.rangeWrapper {
    align-self: center;
}

.rangeThumb {
    background-color: green;
    width: 1.1875rem;
    height: 1.1875rem;
    background: linear-gradient(92.89deg, #055398 0%, #023c75 100%);
    box-shadow: 0px 0px 4px var(--PRIMARY-COLOR);
    border-radius: 50%;
}

.rangeValues {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.375rem;
    color: var(--LABEL-TEXT-FONT-COLOR);
}

.rangeTrack {
    height: 0.375rem;
    width: 100%;
    background-color: var(--BORDER-COLOR);
}

input[type='range']::-webkit-slider-runnable-track {
    border: 0.2px solid #010101;
    border-radius: 0.08125rem;
    width: 100%;
    height: 0.525rem;
    cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
    background: linear-gradient(92.89deg, #055398 0%, #023c75 100%);
    box-shadow: 0px 0px 4px var(--PRIMARY-COLOR);
    width: 1.1875rem;
    height: 1.1875rem;
    border-radius: 50%;

    margin-top: -0.875rem;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type='range'] {
    width: 100%;
    margin: 0.8625rem 0;
    -webkit-appearance: none;
}
input[type='range']:focus {
    outline: none;
}

input[type='range']::-moz-range-track {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 0.08125rem;
    width: 100%;
    height: 0.525rem;
    cursor: pointer;
}
input[type='range']::-moz-range-thumb {
    width: 1rem;
    height: 2.25rem;
    background: #c4ffff;
    border: 1px solid #000000;
    border-radius: 0.1875rem;
    cursor: pointer;
}
input[type='range']::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 0.925rem 0;
    color: transparent;
    width: 100%;
    height: 0.525rem;
    cursor: pointer;
}
input[type='range']::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 0.1625rem;
}
input[type='range']::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 0.1625rem;
}
input[type='range']::-ms-thumb {
    width: 1rem;
    height: 2.25rem;
    background: #c4ffff;
    border: 1px solid #000000;
    border-radius: 0.1875rem;
    cursor: pointer;
    margin-top: 0rem;
    /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
    background: #3071a9;
}
input[type='range']:focus::-ms-fill-upper {
    background: #367ebd;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type='range'] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
}

.label {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    display: block;
    transform-origin: top left;
    transform: translate(1.2rem, 0.8rem) scale(1);
    color: var(--INPUT-PLACEHOLDER-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.labelFocused {
    transform: translate(1.2rem, 0.2rem) scale(0.75);
}

.inputFocused {
    padding-top: 0.625rem;
}

.inputPasswordFocused {
    padding-top: 1.25rem;
    border: none;
}

.input {
    background: var(--INPUT-BG-COLOR);
    box-shadow: 0 0.25rem 0.5rem var(--INPUT-BOX-SHADOW-COLOR);
    border-radius: 0.75rem;
    height: 3rem;
    border: none;
    outline: none;
    padding-left: 1.25rem;
    box-sizing: border-box;
    margin-bottom: 0;
    resize: none;

    @media (max-width: 640px) {
        width: 100%;
    }
}

.inputMultiline {
    padding-top: 0.925rem;
}

.input::-webkit-input-placeholder {
    color: var(--INPUT-PLACEHOLDER-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 3.125rem var(--INPUT-BG-COLOR) inset;
    -webkit-text-fill-color: var(--PRIMARY-TEXT-COLOR);
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 3.125rem var(--INPUT-BG-COLOR) inset;
    -webkit-text-fill-color: var(--PRIMARY-TEXT-COLOR);
}

/* Remove arrows from the rigth side of the inputs type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
