@import 'colors.scss', 'typography.scss', 'breakpoints.scss';

html,
body,
#root {
    background-color: var(--BACKGROUND-COLOR);
    font-family: var(--FONT-FAMILY);
    margin: 0;
}
input {
    -webkit-text-fill-color: var(--PRIMARY-TEXT-COLOR);
    color: var(--PRIMARY-TEXT-COLOR);
}
*:focus {
    outline: none;
}
