.socialButton {
    border-radius: 0.75rem;
    height: 3rem;
    width: 9.375rem;
    color: var(--SOCIAL-BUTTON-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    text-transform: capitalize;
    outline: none;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: left;

    &:hover {
        background: var(--SOCIAL-BUTTON-BG-HOVER);
    }

    &:focus {
        background: var(--SOCIAL-BUTTON-BG-FOCUS);
    }

    &:disabled {
        background: var(--SOCIAL-BUTTON-BG-DISABLED);
    }
}

.facebook {
    background: var(--SOCIAL-BUTTON-FB-BG-COLOR);

    &:hover {
        background: var(--SOCIAL-BUTTON-FB-BG-HOVER);
    }

    &:focus {
        background: var(--SOCIAL-BUTTON-FB-BG-FOCUS);
    }

    &:disabled {
        background: var(--SOCIAL-BUTTON-FB-BG-DISABLED);
    }
}

.google {
    background: var(--SOCIAL-BUTTON-GOOGLE-BG-COLOR);
    color: var(--SOCIAL-BUTTON-GOOGLE-BG-TEXT-COLOR);
    border: 1px solid #000000;
}

.civic {
    background: var(--SOCIAL-BUTTON-CIVIC-BG-COLOR);

    &:hover {
        background: var(--SOCIAL-BUTTON-CIVIC-BG-HOVER);
    }

    &:focus {
        background: var(--SOCIAL-BUTTON-CIVIC-BG-FOCUS);
    }

    &:disabled {
        background: var(--SOCIAL-BUTTON-CIVIC-BG-DISABLED);
    }
}

.linkedin {
    background: var(--SOCIAL-BUTTON-LINKEDIN-BG-COLOR);

    &:hover {
        background: var(--SOCIAL-BUTTON-LINKEDIN-BG-HOVER);
    }

    &:focus {
        background: var(--SOCIAL-BUTTON-LINKEDIN-BG-FOCUS);
    }

    &:disabled {
        background: var(--SOCIAL-BUTTON-LINKEDIN-BG-DISABLED);
    }
}

.text {
    margin-left: 0.688rem;
}

.svg {
    margin-left: 1.063rem;
}
