.tooltip {
    position: absolute;
    background: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0px 4px 8px rgba(124, 124, 124, 0.22);
    left: 5.75rem;
    bottom: 1.875rem;
    height: 2.5rem;
}

.arrow {
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    background: #ffffff;
    border-radius: 0.125rem;
    transform: matrix(0.71, -0.67, 0.75, 0.71, 0, 0);
    left: 0.75rem;
    bottom: -0.3125rem;
}
