
.showAllButton {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -4px 8px rgb(124 124 124 / 22%);
    background: var(--POST-BACKGROUND-COLOR);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    color: var(--SEARCH-RESULTS-COLOR);
    z-index: 99;
}

.showAllButton:hover {
    color: var(--SEARCH-SUBSECTION-COLOR);
    cursor: pointer;
}
