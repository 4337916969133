.loginBox {
    margin-top: 7.8125rem;
    background-color: var(--SECONDARY-COLOR);
    min-width: 28rem;
    
    @media (max-width: 730px) {
        margin-top: 1.8125rem;
        min-width: unset;
    }

    a {
        word-break: break-word;
    }
}

.center {
    text-align: center;
}

.center2 {
    text-align: center;
    margin-top: 1rem;
}

.container {
    max-width: 23.5rem;
    padding: 1.25rem;
    background: white;
    margin-top: 12rem;

    @media (max-width: 730px) {
        max-width: 90vw;
    }
}

.headerText {
    font-weight: var(--FONT-WEIGHT-6);
    font-size: var(--FONT-SIZE-1-75);
    color: var(--SECONDARY-TEXT-COLOR);
    top: 2rem;
    word-break: break-word;
}

.email {
    margin-top: 0.75rem;
}

.emailDisabled {
    margin-top: 0.75rem;
}

.password {
    margin: 0.9375rem 0 0 0;
}

.loginButton {
    margin-top: 1.25rem;
    width: 100%;
}

.forgotPassword {
    display: inline-block;
    margin: 1.1875rem 0 0;
    color: var(--PRIMARY-COLOR);
}

.texxxt {
    font-weight: var(--FONT-WEIGHT-6);
    font-size: 1.2rem;
    color: var(--SECONDARY-TEXT-COLOR);
    top: 2rem;
    word-break: break-word;
}

.lock {
    color: var(--FONT-COLOR);
    margin-top: 1.5rem;
    img {
        width: 26px;
        margin-top: -4px;
    }
}