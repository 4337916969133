:root {
    --FONT-FAMILY: 'Rubik';

    --FONT-WEIGHT-1: 100;
    --FONT-WEIGHT-2: 200;
    --FONT-WEIGHT-3: 300;
    --FONT-WEIGHT-4: 400;
    --FONT-WEIGHT-5: 500;
    --FONT-WEIGHT-6: 600;
    --FONT-WEIGHT-7: 700;
    --FONT-WEIGHT-8: 800;
    --FONT-WEIGHT-9: 900;

    --FONT-SIZE-0-62: 0.625rem; // post date
    --FONT-SIZE-0-7: 0.7rem; // footer links
    --FONT-SIZE-0-75: 0.75rem; // forgot password subtitle
    --FONT-SIZE-0-81: 0.8125rem; // post content
    --FONT-SIZE-0-87: 0.875rem; // post content
    --FONT-SIZE-1: 1rem; // headline text and new account title
    --FONT-SIZE-1-125: 1.125rem; // post sidebar
    --FONT-SIZE-1-25: 1.25rem; // subtitle email confirmation
    --FONT-SIZE-1-5: 1.5rem; // title login 2FA
    --FONT-SIZE-1-75: 1.75rem; // login with email header
    --FONT-SIZE-1-78: 1.78rem; // input 2fa code
    --FONT-SIZE-2: 2rem; // headline title

    --LINE-HEIGHT-1-75: 1.75rem; // settings voting power
    --LINE-HEIGHT-2: 2rem; // title login 2FA
}
