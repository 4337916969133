.container{
    flex-grow: 1;
}

.switchWrapper {
    display: flex;
    justify-content: space-between;
}

.switchValue {
    display: flex;
    cursor: pointer;
}

.font {
    font-size: 0.8rem;
    color: var(--PRIMARY-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-5);
    align-self: center;
}

.label {
    @extend .font;
}

.value {
    @extend .font;
}

.switch {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 0.875rem;
}

.switchLine {
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0.5rem;
    border: 1.5px solid #323232;
    transform: rotate(-180deg);
    display: block;
    min-width: 1.875rem;
    min-height: 0.625rem;
}

.switchCircle {
    border-radius: 50%;
    position: absolute;
    display: block;
    min-width: 0.9375rem;
    min-height: 0.9375rem;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: #979797;
}

.switchCircleSelected {
    transform: translateX(15px);
    background: linear-gradient(92.89deg, #055398 0%, #023c75 100%);
    box-shadow: 0px 0px 4px var(--PRIMARY-COLOR);
}

.input {
    display: none;
}
