.bottomButtons {
    min-width: 22.125rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    @media (max-width: 360px) {
        min-width: 18.125rem;
    }
}

.newAccountText {
    margin-top: 1.2rem;
    align-self: center;
    font-weight: var(--FONT-WEIGHT-6);
    font-size: var(--FONT-SIZE-1);
    color: var(--HEADER-TEXT-COLOR);
}

.signUp {
    margin-top: 0.5625rem;
    width: 100%;

    @media (max-width: 360px) {
        width: 100%;
    }
}
