.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: var(--MODAL-BACKDROP-COLOR);
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    top: 6%;
    @media (max-width: 55rem) {
        top: 0;
    }
}

.modal {
    z-index: 100;
    position: relative;
    margin: 1.25rem auto;
    background: white;
    border-radius: 0.75rem;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--BORDER-COLOR);
    padding: 0.5rem 0 1.2rem 0;
    font-weight: var(--FONT-WEIGHT-6);
    color: var(--HEADER-TEXT-COLOR);
}

.modalButtonClose {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    &:hover {
        opacity: 0.8;
    }
}

.modalBody {
    padding: 1.2rem 0;
    color: var(--LABEL-TEXT-FONT-COLOR);
    min-height: 5rem;
    word-break: break-word;
}

.modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.5rem 0;
    justify-content: center;
}

@media (max-width: 1024px) {
    .modalFooter {
        border-top: 1px solid var(--BORDER-COLOR);
    }
}
@media (max-width: 730px) {
    .modal {
        max-width: 90vw;
    }
}
