:root {
    --PRIMARY-COLOR: #0c4e89;
    --PRIMARY-COLOR-GRADIENT: #023c75;
    --PRIMARY-COLOR-HOVER: #0d3f6c;
    --PRIMARY-COLOR-FOCUS: #0c4e89;
    --PRIMARY-COLOR-DISABLED: #6a7b8a;
    --PRIMARY-COLOR-LOADING: rgba(12, 78, 137, 0.8);
    --PRIMARY-TEXT-COLOR: #434343;
    --OPTIONS-SECURITY-TEXT-COLOR: #106aba;
    --OPTIONS-SECURITY-SUBHEADING-COLOR: #6a6a6a;

    --SECONDARY-COLOR: white;
    --SECONDARY-COLOR-HOVER: #deefff;
    --SECONDARY-COLOR-FOCUS: white;
    --SECONDARY-COLOR-DISABLED: #d0d0d0;
    --SECONDARY-COLOR-LOADING: #deefff;
    --SECONDARY-TEXT-COLOR: var(--PRIMARY-COLOR);
    --NO-COMMENTS-TEXT-COLOR: #7ea1c0;
    --SEARCH-SUBSECTION-COLOR: #7ea1c0;
    --SEARCH-RESULTS-COLOR: #0c4e89;

    --LOGIN-SUBTITLE: #4f4f4f;
    --TERNARY-COLOR: white;
    --TERNARY-COLOR-HOVER: #deefff;
    --TERNARY-COLOR-FOCUS: white;
    --TERNARY-COLOR-DISABLED: #d0d0d0;
    --TERNARY-COLOR-LOADING: #deefff;
    --TERNARY-TEXT-COLOR: var(--PRIMARY-COLOR);
    --TERNARY-BORDER-COLOR: #0c4e89;

    --BACKGROUND-COLOR: #f4f4f4;
    --BACKGROUND-COLOR-2: white;
    --BORDER-COLOR: #dfe2ef;

    --FONT-COLOR: #666666;

    --ERROR-COLOR: #eb5757;

    --LINK-TEXT-COLOR: var(--PRIMARY-COLOR);
    --HEADLINE-TEXT-COLOR: white;
    --FOOTER-LINK-TEXT-COLOR: white;
    --FOOTER-LINK-TEXT-COLOR-HOVER: var(--SECONDARY-COLOR-HOVER);

    --INPUT-LABEL-TEXT-COLOR: #7ea1c0;
    --INPUT-BG-COLOR: white;
    --INPUT-BOX-SHADOW-COLOR: rgba(124, 124, 124, 0.22);
    --INPUT-PLACEHOLDER-TEXT-COLOR: #7ea1c0;
    --TEXT-COLOR-VALID: #10ba25;
    --TEXT-COLOR-INVALID: #fe3d3d;

    --NOTIFICATION-NOT-READ: #fe3d3d;
    --NOTIFICATION-READ: #979797;

    --HEADER-TEXT-COLOR: #7ea1c0;
    --HEADER-TEXT-SELECTED-COLOR: #106aba;

    --BOX-BG-COLOR: #f1f2f9;

    --PRIMARY-BUTTON-BG-COLOR: linear-gradient(92.89deg, var(--PRIMARY-COLOR) 0%, var(--PRIMARY-COLOR-GRADIENT) 100%);

    --PRIMARY-BUTTON-BG-HOVER: var(--PRIMARY-COLOR-HOVER);
    --PRIMARY-BUTTON-BG-FOCUS: var(--PRIMARY-COLOR-FOCUS);
    --PRIMARY-BUTTON-BG-DISABLED: var(--PRIMARY-COLOR-DISABLED);
    --PRIMARY-BUTTON-BG-LOADING: var(--PRIMARY-COLOR-LOADING);
    --PRIMARY-BUTTON-TEXT-COLOR: white;

    --SECONDARY-BUTTON-BG-COLOR: var(--SECONDARY-COLOR);
    --SECONDARY-BUTTON-BG-HOVER: var(--SECONDARY-COLOR-HOVER);
    --SECONDARY-BUTTON-BG-FOCUS: var(--SECONDARY-COLOR-FOCUS);
    --SECONDARY-BUTTON-BG-DISABLED: var(--SECONDARY-COLOR-disabled);
    --SECONDARY-BUTTON-BG-LOADING: var(--SECONDARY-COLOR-LOADING);
    --SECONDARY-BUTTON-TEXT-COLOR: var(--PRIMARY-COLOR);

    --TERNARY-BUTTON-BG-COLOR: var(--TERNARY-COLOR);
    --TERNARY-BUTTON-BG-HOVER: var(--TERNARY-COLOR-HOVER);
    --TERNARY-BUTTON-BG-FOCUS: var(--TERNARY-COLOR-FOCUS);
    --TERNARY-BUTTON-BG-DISABLED: var(--TERNARY-COLOR-disabled);
    --TERNARY-BUTTON-BG-LOADING: var(--TERNARY-COLOR-LOADING);
    --TERNARY-BUTTON-TEXT-COLOR: var(--PRIMARY-COLOR);
    --TERNARY-BUTTON-BD-COLOR: var(--TERNARY-BORDER-COLOR);

    --SOCIAL-BUTTON-BG-HOVER: #deefff;
    --SOCIAL-BUTTON-BG-FOCUS: white;
    --SOCIAL-BUTTON-BG-DISABLED: #d0d0d0;
    --SOCIAL-BUTTON-TEXT-COLOR: white;

    --SOCIAL-BUTTON-GOOGLE-BG-COLOR: white;
    --SOCIAL-BUTTON-GOOGLE-BG-TEXT-COLOR: black;

    --SOCIAL-BUTTON-FB-BG-COLOR: #1976d2;
    --SOCIAL-BUTTON-FB-BG-HOVER: #306ca8;
    --SOCIAL-BUTTON-FB-BG-FOCUS: #1976d2;
    --SOCIAL-BUTTON-FB-BG-DISABLED: #d0d0d0;

    --SOCIAL-BUTTON-CIVIC-BG-COLOR: #3ab03e;
    --SOCIAL-BUTTON-CIVIC-BG-HOVER: #308833;
    --SOCIAL-BUTTON-CIVIC-BG-FOCUS: #3ab03e;
    --SOCIAL-BUTTON-CIVIC-BG-DISABLED: #d0d0d0;

    --SOCIAL-BUTTON-LINKEDIN-BG-COLOR: var(--PRIMARY-COLOR);
    --SOCIAL-BUTTON-LINKEDIN-BG-HOVER: var(--PRIMARY-COLOR-HOVER);
    --SOCIAL-BUTTON-LINKEDIN-BG-FOCUS: var(--PRIMARY-COLOR-FOCUS);
    --SOCIAL-BUTTON-LINKEDIN-BG-DISABLED: var(--PRIMARY-COLOR-DISABLED);

    --LABEL-TEXT-FONT-COLOR: #6a6a6a;
    --SECONDARY-COLOR-DARKER: #106aba;

    --POST-BACKGROUND-COLOR: white;
    --POST-HEADER-TEXT-FONT-COLOR: #999999;

    --SIDEBAR-SCROLL-COLOR: #cfcfcf;

    --MODAL-BACKGROUND-COLOR: white;
    --MODAL-BACKDROP-COLOR: rgba(3, 24, 43, 0.2);

    --VIDEO-PREVIEW-OVERLAY-COLOR: rgba(0, 0, 0, 0.6);

    --COMMON-BACKGROUND-COLOR: white;

    --ONLINE-STATUS-WHITE: white;
    --ONLINE-STATUS-GREEN: green;
    --ONLINE-STATUS-RED: red;
    --ONLINE-STATUS-GREY: grey;

}
