.container {
    margin-bottom: 1.25rem;
    margin: 0.313;
    margin-top: 0.75rem;
    span {
        font-family: var(--FONT-FAMILY);
        font-weight: var(--FONT-WEIGTH-5);
        font-size: var(--FONT-SIZE-0-75);
        line-height: 12px;
        color: var(--PRIMARY-TEXT-COLOR);
    }
}
.img {
    margin-right: 0.5rem;
}
.patterns {
    margin-top: 1rem;
}
.default {
    color: var(--PRIMARY-TEXT-COLOR);
    font-family: var(--FONT-FAMILY);
    font-weight: var(--FONT-WEIGTH-5);
    font-size: var(--FONT-SIZE-0-75);
    line-height: 0.75rem;
    margin-bottom: 0.313rem;
    margin-right: 0.625rem;
    align-items: center;
}
.valid {
    color: var(--TEXT-COLOR-VALID);
}

.invalid {
    color: var(--TEXT-COLOR-INVALID);
}
