.radioBox {
    width: 24px;
    height: 24px;
    border-radius: 100rem;
    border: 2px solid var(--PRIMARY-COLOR);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radioBall {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 100%;
    background-color: var(--PRIMARY-COLOR);
}
