.box {
    max-width: 22.125rem;
    background: var(--SECONDARY-COLOR);
    border-radius: 0.75rem;

    @media (max-width: 640px) {
        max-width: inherit;
        width: 100%;
    }
}

.innerBox {
    margin: 1.25rem;
    display: inline-block;
    width: -webkit-fill-available;
}
