.button {
    border-radius: 0.75rem;
    min-height: 3rem;
    min-width: 10rem;
    color: var(--PRIMARY-BUTTON-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    outline: none;
    cursor: pointer;
    border: none;

    // @media (max-width: 640px) {
    //     min-width: 18.625rem;
    // }
    // @media (max-width: 360px) {
    //     min-width: 15.625rem;
    // }
}

.red {
    background: tomato;
}

.blue {
    background: var(--PRIMARY-BUTTON-BG-COLOR);

    &:hover {
        background: var(--PRIMARY-BUTTON-BG-HOVER);
    }

    &:focus {
        background: var(--PRIMARY-BUTTON-BG-FOCUS);
    }

    &:disabled {
        background: var(--PRIMARY-BUTTON-BG-DISABLED);
    }
}

.buttonOutline {
    border-radius: 0.75rem;
    min-height: 3rem;
    color: var(--SECONDARY-BUTTON-TEXT-COLOR);
    font-weight: var(--FONT-WEIGTH-6);
    cursor: pointer;
    border: 0.125rem solid var(--SECONDARY-BUTTON-TEXT-COLOR) !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.secondary {
    color: var(--SECONDARY-BUTTON-TEXT-COLOR);
    background: var(--SECONDARY-BUTTON-BG-COLOR);

    &:hover {
        background: var(--SECONDARY-BUTTON-BG-HOVER);
    }

    &:focus {
        background: var(--SECONDARY-BUTTON-BG-FOCUS);
    }

    &:disabled {
        background: var(--SECONDARY-BUTTON-BG-DISABLED);
    }
}

.ternary {
    color: var(--TERNARY-BUTTON-TEXT-COLOR);
    background: var(--TERNARY-BUTTON-BG-COLOR);
    border-color: var(--TERNARY-BUTTON-BD-COLOR);
    border: 1px solid;
    border-radius: 0.75rem;

    &:hover {
        background: var(--TERNARY-BUTTON-BG-HOVER);
    }

    &:focus {
        background: var(--TERNARY-BUTTON-BG-FOCUS);
    }

    &:disabled {
        background: var(--TERNARY-BUTTON-BG-DISABLED);
    }
}

.loadingButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:disabled {
        background: var(--PRIMARY-BUTTON-BG-COLOR);
    }
}

.icon {
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
}

.blueTextColor {
    color: var(--PRIMARY-COLOR);
}
