.inputWrapper {
    position: relative;
    display: inline-flex;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}

.label {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    display: block;
    transform-origin: top left;
    transform: translate(1.2rem, 0.8rem) scale(1);
    color: var(--INPUT-PLACEHOLDER-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.labelFocused {
    transform: translate(1.2rem, 0.2rem) scale(0.75) !important;
}

.inputFocused {
    padding-top: 0.625rem;
}

.inputPasswordFocused {
    padding-top: 1.25rem;
    border: none !important;
    box-shadow: 0 0.25rem 0.5rem var(--INPUT-BOX-SHADOW-COLOR) !important;
}

.input {
    background: var(--INPUT-BG-COLOR);
    box-shadow: 0 0.25rem 0.5rem var(--INPUT-BOX-SHADOW-COLOR);
    -webkit-appearance: none;
    border-radius: 0.75rem;
    height: 3rem;
    border: none;
    outline: none;
    padding-left: 1.25rem;
    padding-right: 3rem;
    box-sizing: border-box;
    margin-bottom: 0;
    resize: none;

    @media (max-width: 640px) {
        width: 100%;
    }
}

.error {
    border: 1px solid #fe3d3d !important;
}

.errorLabel {
    color: #eb5757;
}

.inputFocusedForSearch {
    background: var(--INPUT-BG-COLOR) !important;
    -webkit-appearance: none;
    border: 1px solid var(--BORDER-COLOR) !important;
    border-radius: 0.75rem;
    height: 3rem;
    border: none;
    outline: none;
    padding-top: 0rem !important;
    padding-left: 1.25rem;
    box-sizing: border-box;
    margin-bottom: 0;
    resize: none;

    @media (max-width: 640px) {
        width: 100%;
    }
}

//styles for Search input
.searchInput {
    background: none;
    height: 2.5rem;
    border: none;
    padding-left: 3rem;
    box-sizing: border-box;
    margin-bottom: 0.125rem;
    resize: none;
    box-shadow: none;
    outline: none;

    @media (max-width: 640px) {
        width: 100%;
    }
}

.labelForSearch {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    padding-left: 2rem;
    display: block;
    margin-top: -0.375rem;
    transform-origin: top left;
    transform: translate(1.2rem, 0.8rem) scale(1);
    font-weight: normal;
    font-size: var(--FONT-SIZE-1-125);
    color: var(--FONT-COLOR);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @media (max-width: 350px) {
        padding-left: 1.5rem !important;
    }
}

.labelFocusedForSearch {
    display: none;
}

.forMobileInput {
    height: 3rem !important;
}

.searchImage {
    position: absolute;
    margin-top: 0.6875rem;
    margin-bottom: 0rem;
    margin-right: 0.625rem;
    margin-left: 0.75rem;
}

.searchImage.searchImageFosued {
    margin-top: 0.6875rem;
}

.inputMultiline {
    padding-top: 0.925rem;
}

.input::-webkit-input-placeholder {
    color: var(--INPUT-PLACEHOLDER-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 3.125rem var(--INPUT-BG-COLOR) inset;
    -webkit-text-fill-color: var(--PRIMARY-TEXT-COLOR);
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 3.125rem var(--INPUT-BG-COLOR) inset;
    -webkit-text-fill-color: var(--PRIMARY-TEXT-COLOR);
}

/* Remove arrows from the rigth side of the inputs type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

// styles for password
.showPassword {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 12px;
}
