.loginBox {
    margin-top: 1.8125rem;
    background-color: var(--SECONDARY-COLOR);
}

.center {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.headerText {
    font-weight: var(--FONT-WEIGHT-6);
    font-size: var(--FONT-SIZE-1-5);
    color: var(--SECONDARY-TEXT-COLOR);
    top: 2rem;
    line-height: var(--LINE-HEIGHT-2);
}

.email {
    @extend .headerText;
    font-size: var(--FONT-SIZE-1-25);
}

.password {
    margin: 0.9375rem 0 0 0;
}

.verify {
    margin-top: 2.5rem;
    width: 100%;
}

.forgotPassword {
    display: inline-block;
    margin: 1.1875rem 0 0;
}

.subtitle {
    display: block;
    font-size: var(--FONT-SIZE-0-75);
    margin: 1rem 1rem 0.75rem;
    color: var(--LOGIN-SUBTITLE);
}

.code {
    color: var(--INPUT-LABEL-TEXT-COLOR);
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.625rem;
    font-weight: var(--FONT-WEIGHT-6);
    text-align: center;
}

.codeInput {
    font-weight: var(--FONT-WEIGHT-6);
    text-align: center;
    letter-spacing: 0.5rem;
    font-size: var(--FONT-SIZE-1-78);
}

.differentMethodButton {
    border: 1px solid var(--PRIMARY-COLOR);
    margin-top: 1.5rem;
    width: 100%;
}

.resend {
    font-size: 0.75rem;
    color: var(--PRIMARY-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    margin-top: 1rem;
    text-align: center;
}
