.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.innerBox {
    max-width: 57rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.links {
    margin-bottom: 1.25rem;
    max-width: var(--footer-width);
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 0.0625rem solid var(--SECONDARY-COLOR-DARKER);
    margin-top: 1.25rem;
    @media (max-width: 640px) {
        margin: 1.25rem;
        width: auto;
    }
}

.link {
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: 2.75rem;
    font-size: var(--FONT-SIZE-0-7);
    font-weight: var(--FONT-WEIGHT-5);
    color: white;
    text-decoration: none;
    margin-top: 1.25rem;

    &:hover {
        color: white;
    }
}

.first {
    margin-left: 0;
}

.doNotSell {
    @media (max-width: 640px) {
        margin-left: 0.625rem;
    }
}

.last {
    margin-left: auto;
    @media (max-width: 640px) {
        margin-left: inherit;
    }
}
