:root {
    --footer-width: 80rem;
}
.login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--PRIMARY-COLOR);
    background-image: url('/assets/loginBackground.svg');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.loginDetails {
    flex: 1 0 auto;
}

.fixed {
    width: 56.8125rem;
    margin: 1.25rem;

    @media (max-width: 640px) {
        margin: 0.6rem;
        width: 95%;
    }
}

.logo {
    margin-top: 7.375rem;
    display: block;
    cursor: pointer;

    @media (max-width: 640px) {
        margin-top: 1.875rem;
    }
}

.headline {
    max-width: 23.5625rem;
}

.title {
    margin-top: 2.8125rem;
    font-size: var(--FONT-SIZE-2);
    line-height: 2.375rem;
    display: block;
    color: var(--HEADLINE-TEXT-COLOR);
}

.text {
    margin-top: 1.375rem;
    font-size: var(--FONT-SIZE-1);
    line-height: 1.1875rem;
    display: block;
    color: var(--HEADLINE-TEXT-COLOR);
}

.loginInner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 640px) {
        display: block;
    }
}
