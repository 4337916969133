.box {
    margin-top: 1.125rem;
    min-height: 11.3125rem;
    padding-top: 0.0625rem;
}

.header {
    display: block;
    text-align: center;
    font-weight: var(--FONT-WEIGHT-6);
    color: var(--HEADER-TEXT-COLOR);
    top: 2rem;
    margin-bottom: 0.375rem;
}

.buttonsRow {
    display: flex;
    min-width: 19.625rem;
}

.button:first-child {
    margin-left: 0;
}

.button {
    margin-top: 0.5625rem;
    margin-left: 0.9375rem;

    @media (max-width: 640px) {
        margin-left: 1.25rem;
        max-width: inherit;
        width: 100% !important; // TODO chrome don't let me get it replaced
    }
}
