.selectWrapper {
    position: relative;
    display: inline-flex;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
    background: var(--INPUT-BG-COLOR);
    box-shadow: 0 0.25rem 0.5rem var(--INPUT-BOX-SHADOW-COLOR);
    border-radius: 0.75rem;
    height: 3rem;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 0;
}

.label {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    display: block;
    transform-origin: top left;
    transform: translate(1.2rem, 0.8rem) scale(1);
    color: var(--INPUT-PLACEHOLDER-TEXT-COLOR);
    font-weight: var(--FONT-WEIGHT-6);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    max-width: 64%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text {
    font-size: 1rem;
    font-weight: var(--FONT-WEIGHT-4);
    line-height: 1.8;
    color: #0a0a0a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 1.1rem;
    padding-left: 1.25rem;
    color: var(--PRIMARY-TEXT-COLOR);

    width: 90%;
}

.text2FA {
    width: 85%;
}

.labelFocused {
    transform: translate(1.2rem, 0.2rem) scale(0.75);
    max-width: inherit;
}

.image {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    transition: 0.2s ease;
}

.selectShowingOptions {
    border-bottom-right-radius: initial;
    border-bottom-left-radius: initial;
}

.selectOptions {
    display: none;
}

.showSelectOptions {
    position: absolute;
    top: 3rem;
    width: 100%;
    padding: 0;
    list-style: none;
    z-index: 2;
    background: var(--INPUT-BG-COLOR);
    box-shadow: 0 0.25rem 0.5rem var(--INPUT-BOX-SHADOW-COLOR);
    border-radius: 0.75rem;
    border-top-right-radius: initial;
    border-top-left-radius: initial;
    max-height: 11.5rem;
    overflow-x: auto;

    ul {
        list-style: none;
        margin-left: 0;
        margin-bottom: 0.6rem;
    }

    li {
        color: var(--PRIMARY-TEXT-COLOR);
        padding-left: 1.2rem;
    }

    li:hover {
        background-color: var(--SECONDARY-COLOR-HOVER);
    }
}

.option {
    border: 0;
    cursor: pointer;
    text-decoration: none;
    padding-top: 0.375rem;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 0.375rem;
}

.input {
    display: none;
}

.selectOptions {
    display: none;
}

.showSelectOptions {
    display: block;
}
